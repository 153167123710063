@use 'styles/mixins';

section.ignoreSpacing {
  display: inline;
  + section {
    margin-top: 0 !important;
    @include mixins.media {
      margin-top: 0 !important;
    }
  }
}
