@use 'styles/mixins';
@use 'styles/variables';

.ccSelectorModal {
  position: fixed;
  z-index: 400;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixins.media {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ccSelectorModal:hover {
  overflow: hidden;
}

.ccSelectorContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 92vw;
  height: 316px;
  padding: 2.5rem 2rem;
  background: #f9f9f9;
  border-radius: 0.2rem;

  @include mixins.tablet {
    width: 80vw;
    height: 350px;
    padding: 3rem 2.5rem;
  }

  @include mixins.media {
    width: 600px;
    height: 300px;
    padding: 55px 40px;
  }
}

.updateContent {
  font-size: 24px;
  text-align: left;

  color: #3c3c3b;
  @include mixins.media {
    font-size: 28px;
  }
}

.countryDetection {
  font-size: 14px;
  line-height: 20px;
  margin: auto 0;
  span {
    font-weight: 600;
  }
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.8rem;

  @include mixins.media {
    justify-content: space-between;
    gap: 0;
    flex-direction: row;
  }
}

.confirmContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  background: #3c3c3b;
  border-radius: 0.2rem;
  font-size: 14px;
  color: #ffffff;

  @include mixins.media {
    width: 172px;
    margin: 0;
  }
}

.countryCode {
  margin-right: 1.1rem;
}
.confirmationButtonContent {
  position: relative;
  width: 100%;
  height: 48px;
  background: #ebebeb;
  border-radius: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include mixins.bodyTypography;
  font-size: 14px;

  @include mixins.media {
    width: 330px;
    margin: 0;
  }
}

.locationsContainer {
  position: absolute;
  top: 48px;
  width: 100%;
  max-height: 240px;
  overflow-x: hidden;

  @include mixins.media {
    position: absolute;
    width: 330px;
    margin-left: auto;
  }
}
.countrySelector {
  width: inherit;
  height: 48px;
  background: #ebebeb;
  display: flex;
  align-items: center;
  padding-left: 15px;
  overflow: hidden;
  @include mixins.bodyTypography;
  font-family: variables.$font-headline;
  font-size: 14px;
}

.confirmationButtonContent:hover,
.countrySelector:hover {
  cursor: pointer;
}

.countrySelector:hover {
  background: #ffffff;
}

.button {
  @include mixins.standardButton;
  font-size: 14px;
  padding: 0 0 0 15px;
}

.resetMargin {
  margin: 0;
}

.chevronContainer {
  margin-right: 1rem;

  @include mixins.media {
    margin-right: 15px;
  }
}
